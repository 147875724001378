import React, { useState } from 'react'
import FormControl from '../../../components/formControl'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Spinner from '../../../components/spinner'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import { ListUsersQuery, customSearchUsers } from '../../../customQueries/customQueries'
import { createSocietyUser } from '../../../graphql/mutations'
import { toast } from 'react-toastify'

function CreateSocietyUser() {
    const params = useParams()
    const navigate = useNavigate()
    const [spinner, showSpinner] = useState(false)
    const [formData, setFormData] = useState({
        phone: ''
    })

    const formSchema = Yup.object().shape({
        phone: Yup.string().required('Required'),
    })


    const handleAddSociety = async (form) => {
        // showSpinner(true)
        try {
            const userData = await API.graphql({
                query: customSearchUsers,
                variables: {
                    filter: { phone: { eq: `+91${form.phone}` } }
                }
            })
            if (userData.data.searchUsers.items[0] && !userData.data.searchUsers.items[0].societies.items?.some((x) => x.societyId === params.id && x.role === 'ADMIN')) {
                await API.graphql({
                    query: createSocietyUser,
                    variables: {
                        input: {
                            name: form.name,
                            societyId: params.id,
                            userId: userData.data.searchUsers.items[0].id,
                            name: userData.data.searchUsers.items[0]?.name,
                            role: 'ADMIN',
                        }
                    }
                }).then(res => {
                    showSpinner(true)
                    setTimeout(() => {
                        navigate(`/society/${params.id}/users`)
                        toast.success('User added successfully')
                        showSpinner(false)
                    }, 3000)
                })
            }
            else {
                toast.warning('This user already exist in this society')
                showSpinner(false)
            }
        } catch (err) {
            console.log(err)
            showSpinner(false)
        }
    }


    return (
        <section>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h1 className='header-title'>
                                            Add User
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleAddSociety} validateOnChange={false} validateOnBlur={false}>
                            {({ handleChange, handleSubmit, setFieldValue, values, touched, errors }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <FormControl type="phone" title="Phone" name='phone' placeholder={'Phone number'} value={values.phone} error={errors.phone} onChange={handleChange} required={true} />

                                        <div>
                                            <Spinner show={spinner}>
                                                <button className="btn w-100 btn-primary" type='submit'>Add User</button>
                                            </Spinner>
                                            <Link to={`/society/${params.id}/users`}>
                                                <div className='btn w-100 btn-link text-muted mt-2' type='button'>
                                                    Cancel
                                                </div>
                                            </Link>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                        <div className='text-muted text-start' type='button'>
                            New User? <Link to={`/society/${params.id}/user/create`}>Click here</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateSocietyUser
