import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Overview from './societyUsers/overview'
import Users from './users'
import Settings from './societyUsers/settings'
import Subscription from './societyUsers/subscription'
import CreateUser from './createUser'
import { API } from 'aws-amplify'
import { getSociety, searchSocieties } from '../../../graphql/queries'


function SocietyAdminLayout() {
    const navigate = useNavigate()
    const params = useParams()

    const [societyZohoId, setSocietyZohoId] = useState()
    const [society, setSociety] = useState()
    const [page, setPage] = useState('Overview')

    useEffect(() => {
        handleGetSociety()
        handelGetSocietyData()
    }, [])

    const handleGetSociety = () => {
        API.graphql({
            query: searchSocieties,
            variables: {
                filter: { id: { eq: params.id } }
            }
        }).then((res) => {
            setSociety(res.data.searchSocieties.items[0].name)
        })
    }

    const handleGoBack = () => {
        navigate('/society')
    }

    const handelGetSocietyData = () => {
        API.graphql({
            query: searchSocieties,
            variables: {
                filter: { id: { eq: params.id } }
            }
        }).then((res) => {
            setSocietyZohoId(res.data.searchSocieties.items[0].zohoId)
        })
    }


    return (
        <section>
            <div className='pt-5'>
                <div className='header-body'>
                    <div className='row align-items-end'>
                        <div className='col'>
                            <h6 className='header-pretitle'>{society}</h6>
                            <h1 className='m-0'>{page}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header">
                <div className="header-body d-flex align-items-end justify-content-between">
                    <div className="row d-flex align-items-center">
                        <div className="col">
                            <ul className="nav nav-tabs nav-overflow header-tabs">
                                <li className="nav-item" onClick={() => setPage('Overview')}>
                                    <Link className={page === 'Overview' ? 'nav-link active' : "nav-link"}>
                                        Overview
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => setPage('Users')}>
                                    <Link className={page === 'Users' ? 'nav-link active' : "nav-link"}>
                                        Users
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => setPage('Settings')}>
                                    <Link className={page === 'Settings' ? 'nav-link active' : "nav-link"}>
                                        Settings
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => setPage('Subscription')}>
                                    <Link className={page === 'Subscription' ? 'nav-link active' : "nav-link"}>
                                        Subscription
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        {
                            page === 'Users' &&
                            <span className='me-3'>
                                <Link to={`/society/${params.id}/user/create`}>
                                    <button className='btn btn-primary'>Create</button>
                                </Link>
                            </span>
                        }
                      
                        {
                            page === 'Settings' && !societyZohoId &&
                            <span className='me-3'>
                                <Link to={`/society/${params.id}/link/zoho`}>
                                    <button className='btn btn-primary'>Link To Zoho</button>
                                </Link>
                            </span>
                        }
                        {
                            page !== 'CreateUser' &&
                            <button className='btn btn-primary' onClick={handleGoBack}>Back</button>
                        }
                    </div>
                </div>
            </div>
            <div className='tab-content'>
                <div className='tab-pane fade active show' id='companiesListPane' role='tabpanel' aria-labelledby='companiesListTab'>
                    {page === 'Overview' &&
                        <Overview />
                    }
                    {page === 'Users' &&
                        <Users />
                    }
                    {page === 'Settings' &&
                        <Settings />
                    }
                    {page === 'Subscription' &&
                        <Subscription />
                    }
                    {
                        page === 'CreateUser' &&
                        <CreateUser />
                    }
                </div>
            </div>
        </section>
    )
}

export default SocietyAdminLayout
