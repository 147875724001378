export const custumGetAllSocietyData = /* GraphQL */ `
  query GetSociety($id: ID!) {
    getSociety(id: $id) {
      id
      type
      name
      cityId
      address
      photo
      buildings {
        items {
        id
        name
        societyId
      }
      }
      units {
        items {
        id
        name
        size
        type
      }
      }
      vechicles {
        items {
        id
        name
        number
        type
        societyId
        unitId
      }
      }
      amenities {
        items {
        name
        buildingId
        id
        societyId
      }
      }
      residents {
        items {
        userId
        updatedAt
        unitId
        type
        societyId
        buildingId
        approved
        id
      }
      }
      users {
        items {
        id
        role
        societyId
        user {
          id
          name
          email
          photo
          phone
        }
      }
      }
      createdAt
      updatedAt
    }
  }
`;

export const ListUsersQuery = `
 query ListUsersByFilter($phone: String!)
  { 
    listUsers(filter: { phone: { eq: $phone } })
     {
       items {
      societies {
        items {
          societyId
          userId
        }
      }
      id
    }
  }
    }`;

export const customGetUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      phone
      photo
      birthday
      gender
      role
     residents {
      items {
        id
      }
    }
      societies {
      items {
        id
      }
    }
    }
  }
`;

export const customSearchSocieties = /* GraphQL */ `
  query SearchSocieties(
    $filter: SearchableSocietyFilterInput
    $sort: [SearchableSocietySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSocietyAggregationInput]
  ) {
    searchSocieties(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const customSearchSocietyUsers = /* GraphQL */ `
  query SearchSocietyUsers(
    $filter: SearchableSocietyUserFilterInput
    $sort: [SearchableSocietyUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSocietyUserAggregationInput]
  ) {
    searchSocietyUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
      id
      role
      societyId
      updatedAt
      name
      user {
        email
        id
        phone
        photo
        name
      }
    }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const customSearchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
      email
      gender
      id
      name
      societies {
        items {
          id
          role
          societyId
        }
      }
    }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const customSearchUsersCount = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
      total
    }
  }
`;