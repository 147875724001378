import { API, Storage } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { customSearchSocietyUsers, custumGetAllSocietyData } from '../../../customQueries/customQueries'
import { Link, useParams } from 'react-router-dom'
import Spinner from '../../../components/spinner'
import { Modal } from 'react-bootstrap'
import FormControl from '../../../components/formControl'
import { Formik } from 'formik'
import * as Yup from 'yup';
import { deleteSocietyUser, updateSocietyUser, updateUser } from '../../../graphql/mutations'
import swal from 'sweetalert'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'


function Users() {
    const userRole = require('../../../data/userRole.json')
    const params = useParams()
    const [users, setUsers] = useState()
    const [nextArr, setNextArr] = useState([])
    const [spinner, showSpinner] = useState()
    const [modal, showModal] = useState()
    const [search, setSearch] = useState('')

    const [formData, setFormData] = useState({
        name: '',
        image: '',
        society_role: '',
        id: '',
        societyUserId: ''
    })

    const formSchema = Yup.object().shape({
    })

    const getSocietyUsers = (token, preve) => {
        showSpinner(true)
        try {
            API.graphql({
                query: customSearchSocietyUsers,
                variables: {
                    sort: { direction: 'desc', field: 'createdAt' },
                    filter: { societyId: { eq: params.id }, role: { eq: "ADMIN" } },
                    limit: 20,
                    nextToken: token || null
                }
            }).then((res) => {
                if (!isEmpty(res.data?.searchSocietyUsers?.items)) {
                    if (res?.data?.searchSocietyUsers?.nextToken && preve) {
                        let newUsers = res?.data?.searchSocietyUsers?.nextToken
                        setNextArr((prev) => ([...prev, newUsers]))
                    }
                    setUsers(res?.data?.searchSocietyUsers?.items);
                }
                showSpinner(false)
            })
        } catch (err) {
            console.log(err)
        }
    }

    const onNext = () => {
        if (nextArr[nextArr.length - 1] !== null) {
            getSocietyUsers(nextArr[nextArr.length - 1], true)
        }
    }

    const onPrev = () => {
        if (nextArr.length <= 3) {
            getSocietyUsers(null, false)
            if (nextArr.length === 3) {
                setNextArr(nextArr.slice(0, -1))
            }
        } else {
            getSocietyUsers(nextArr[nextArr.length - 3], false)
            setNextArr(nextArr.slice(0, -1))
        }
    }

    const handleRemove = async (societyUser, index) => {
        swal({
            title: `Delete`,
            text: `Are you sure you want to delete Resident?`,
            buttons: ['Cancel', 'Remove'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            showSpinner(true)
            if (status) {
                API.graphql({
                    query: deleteSocietyUser,
                    variables: {
                        input: {
                            id: societyUser.id
                        }
                    }
                }).then(() => {
                    const temp = [...users]
                    temp.splice(index, 1)
                    setUsers(temp)
                    toast.success(`${societyUser.user?.name} Removed from ${societyUser.name} successfully`)
                }).catch((err) => toast.error(err.message))
            }
            showSpinner(false)
        }
        )
    }

    const handleEdit = (societyUser) => {
        if (societyUser?.role !== 'ADMIN') {
            toast.warning('Can not update the society users')
        } else {
            setFormData({
                name: societyUser.user.name,
                id: societyUser.user.id,
                society_role: societyUser.role,
                image: societyUser.user.photo,
                societyUserId: societyUser.id
            })
            showModal(true)
        }
    }


    const handleEditSocietyUser = async (form) => {
        try {
            let updatedUser = await API.graphql({
                query: updateUser,
                variables: {
                    input: {
                        id: form.id,
                        email: form?.email?.trim()?.toLowerCase(),
                        photo: form.image,
                        name: form.name
                    }
                }
            })
            let updatedSociety = await API.graphql({
                query: updateSocietyUser,
                variables: {
                    input: {
                        name: form.name,
                        id: formData.societyUserId,
                        role: form.society_role,
                        name: form.name
                    }
                }
            })
            setUsers((prevState) => {
                const index = prevState.findIndex((user) => user?.user?.id === form.id);
                prevState[index].role = updatedSociety?.data?.updateSocietyUser?.role;
                prevState[index].user = updatedUser.data.updateUser
                return [...prevState];
            });
            showModal(false)
            toast.success('User updated successfully')
        } catch (err) {
            console.log(err)
        }
    }

    const handleUploadImage = async (e, callback, name) => {
        const file = e.target.files[0]
        const stored = await Storage.put(`buckler-${Math.random().toString(36).substring(2, 15)}.${file.name.split('.')[1]}`, file, { contentType: file.type });
        const url = await Storage.get(stored.key, { level: 'public' })
        let ImageUrl = url.split('?')[0]

        if (ImageUrl) {
            const event = { target: { name: name || '', value: ImageUrl } }
            callback(event)
        }
    }

    useEffect(() => {
        getSocietyUsers(null, true)
    }, [])

    const handelSearchSocietyUser = (e) => {
        if (e.target.value) {
            setSearch(e.target.value)
            API.graphql({
                query: customSearchSocietyUsers,
                variables: {
                    filter: { name: { matchPhrasePrefix: e.target.value }, role: { matchPhrasePrefix: 'ADMIN' }, societyId: { eq: params.id } }
                }
            }).then((res) => {
                setUsers(res.data.searchSocietyUsers.items)
            })
        } else {
            setSearch('')
            getSocietyUsers(nextArr[nextArr.length - 2], false)
        }
    }

    return (
        <section>
            <div className='card' data-list='{&quot;valueNames&quot;: [&quot;item-name&quot;, &quot;item-industry&quot;, &quot;item-location&quot;, &quot;item-owner&quot;, &quot;item-created&quot;], &quot;page&quot;: 10, &quot;pagination&quot;: {&quot;paginationClass&quot;: &quot;list-pagination&quot;}}' id='companiesList'>
                <div className='card-header'>
                    <div className='row align-items-center'>
                        <div className='col'>
                            <form>
                                <div className='input-group input-group-flush input-group-merge input-group-reverse'>
                                    <input className='form-control list-search' type='search' placeholder='Search' onChange={(e) => handelSearchSocietyUser(e)} />
                                    <span className='input-group-text'>
                                        <i className='fe fe-search'></i>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='table-responsive'>
                    <Spinner show={spinner}>
                        <table className='table table-hover table-nowrap card-table'>
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Role</th>
                                    <th className='text-center'>Actions</th>
                                </tr>
                            </thead>
                            <tbody className='list fs-base'>
                                {users?.map((item, index) => (
                                    <tr key={index}>
                                        {
                                            item?.user?.name &&
                                            <>
                                                <td>
                                                    <div className='avatar avatar-xs align-middle me-2'>
                                                        <img className='avatar-img rounded-circle' src={item?.user?.photo} alt='...' />
                                                    </div>
                                                </td>
                                                <td>
                                                    {item?.user?.name}
                                                </td>
                                                <td>
                                                    {item?.user?.phone}
                                                </td>
                                                <td>{item?.role}</td>
                                                <td className='text-center'>
                                                    <div className='dropdown'>
                                                        <a href='#' className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                                                            <i className='fe fe-more-vertical'></i>
                                                        </a>
                                                        <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                                            <div className='dropdown-item cursor-pointer' href='#' onClick={() => { handleEdit(item) }}>
                                                                Edit
                                                            </div>
                                                            <div className='dropdown-item cursor-pointer' href='#' onClick={() => { handleRemove(item) }}>
                                                                Remove user
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {!search &&
                            <div className='card-footer d-flex justify-content-between'>
                                <ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
                                    <li className='page-item' >
                                        <Link className='page-link ps-0 pe-4 border-end' onClick={onPrev} >
                                            <i className='fe fe-arrow-left me-1'></i> Prev
                                        </Link>
                                    </li>
                                </ul>
                                <ul className='list-pagination-next pagination pagination-tabs card-pagination'>
                                    <li className='page-item'>
                                        <Link className='page-link ps-4 pe-0 border-start' onClick={onNext}>
                                            Next <i className='fe fe-arrow-right ms-1'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        }
                    </Spinner>
                </div>
            </div>
            <Modal show={modal} onHide={() => showModal(false)}>
                <Modal.Body>
                    <h3>Edit Society Admin</h3>
                    <hr />
                    <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleEditSocietyUser} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                        {({ handleChange, handleSubmit, values, errors, setFieldValue, touched }) => {
                            const societyRoles = values?.society_role ? userRole?.filter((x) => x?.value === values?.society_role)[0] : null;
                            return (
                                <form noValidate onSubmit={handleSubmit}>
                                    <FormControl type='text' name='name' title='Society Name'
                                        value={values?.name} error={errors.name} onChange={handleChange} />

                                    <FormControl type='select1' title='Select Role' name='society_role' value={societyRoles} options={userRole} error={errors.society_role} onChange={handleChange} />

                                    <div className='form-group mt-3'>
                                        {values?.image && <img src={values?.image} width={200} alt='' className='preview' />}
                                        <div>
                                            <label>Image</label>
                                            <input name='image' type='file' accept='image/*' onChange={(e) => { handleUploadImage(e, handleChange, 'image') }} className='form-control' />
                                            {errors.image && touched.image && <div className='text-danger mt-2 ms-1 h5'>{errors.image}</div>}
                                        </div>
                                    </div>

                                    <Spinner show={spinner}>
                                        <button className='btn btn-primary' type='submit'>Update</button>
                                        <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showModal(false)}>Cancel</button>
                                    </Spinner>
                                </form>
                            )
                        }
                        }
                    </Formik>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default Users
