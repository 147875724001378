import { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { searchSocieties } from '../../../../graphql/queries'
import Spinner from '../../../../components/spinner'

function Settings() {
    const params = useParams()
    const [society, setSociety] = useState()
    const [spinner, showSpinner] = useState()
    const [societyZohoId, setSocietyZohoId] = useState()

    const handleGetSociety = () => {
        API.graphql({
            query: searchSocieties,
            variables: {
                filter: { id: { eq: params.id } }
            }
        }).then((res) => {
            setSociety(res.data.searchSocieties.items[0].name)
        })
    }

    const handelGetSocietyData = () => {
        API.graphql({
            query: searchSocieties,
            variables: {
                filter: { id: { eq: params.id } }
            }
        }).then((res) => {
            setSocietyZohoId(res.data.searchSocieties.items[0].zohoId)
        })
    }

    useEffect(() => {
        handleGetSociety()
        handelGetSocietyData()
    }, [])

    return (
        <div className='tab-content'>
            <div className='tab-pane fade active show' id='companiesListPane' role='tabpanel' aria-labelledby='companiesListTab'>
                <div className='card' id='companiesList'>
                    <Spinner show={spinner}>
                        <div className='table-responsive'>
                            <table className='table table-hover table-nowrap card-table'>
                                <thead>
                                    <tr>
                                        <th>Society Name</th>
                                        <th className='text-center'>ZohoId</th>
                                    </tr>
                                </thead>
                                <tbody className='list fs-base'>
                                    {
                                        societyZohoId ? <tr>
                                            <td>{society} </td>
                                            <td className='text-center'>{societyZohoId}</td>
                                        </tr> :
                                            <tr className=' text-center text-muted'>
                                                <td colSpan={'7'}>
                                                    <h4 className='m-0'>
                                                        No data found
                                                    </h4>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Spinner>
                </div>
            </div>
        </div>
    )
}

export default Settings
