/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://onoazdkxazdotl2outrnhc6rfu.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-no2wr7g4uje4rbpmrpbc7yx62q",
    "aws_cloud_logic_custom": [
        {
            "name": "restApi",
            "endpoint": "https://d01ihapiti.execute-api.ap-south-1.amazonaws.com/dev",
            "region": "ap-south-1"
        },
        {
            "name": "zohoMiddleware",
            "endpoint": "https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev",
            "region": "ap-south-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-south-1:2a5b5a39-dc5f-4546-822e-7b994b83ee25",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_ljSuEJ6sX",
    "aws_user_pools_web_client_id": "7ujg2u7el7pt644qbsqhelma20",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "buckler-d04add60153116-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
