import React from 'react'

const Header = ({ title, onclick, buttonTitle }) => {
    return (
        <div className="header">
            <div className="header-body">
                <div className="row align-items-end">
                    <div className="col">
                        <h1 className="header-title">{title}</h1>
                    </div>
                    {onclick && <div className="col-auto"><div onClick={onclick} className="btn btn-primary lift">{buttonTitle}</div></div>}
                </div>
            </div>
        </div>
    )
}

export default Header