import { Auth } from 'aws-amplify';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

function TopNav() {
    const navigate = useNavigate()

    const handleSignOut = () => {
        Auth.signOut().then((res) => {
            localStorage.clear();
            navigate('/')
        })
    }


    return (
        <nav className='navbar navbar-expand-md navbar-light d-none d-md-flex' id='topbar'>
            <div className='container-fluid'>
                <div className='form-inline me-4 d-none d-md-flex' />
                <div className='navbar-user'>
                    <div className='dropdown'>
                        <Link id='sidebarIcon' className='dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                            <div className='avatar avatar-sm avatar-onlines'>
                                <img src='/img/profile.png' className='avatar-img rounded-circle' alt='...' />
                            </div>
                        </Link>
                        <div className='dropdown-menu dropdown-menu-end' aria-labelledby='sidebarIcon'>
                            <Link to={'/profile'} className='dropdown-item'>Profile</Link>
                            <hr className='dropdown-divider' />
                            <Link className='dropdown-item' onClick={() => handleSignOut()}>Logout</Link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default TopNav
