import { Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import FormControl from '../../../../components/formControl'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Spinner from '../../../../components/spinner'
import NumberFormat from 'react-number-format'
import { API } from 'aws-amplify'
import { updateSociety } from '../../../../graphql/mutations'
import { searchSocieties } from '../../../../graphql/queries'
import { toast } from 'react-toastify'

function LinkToZoho() {
    const navigate = useNavigate()
    const params = useParams()

    const [spinner, showSpinner] = useState()

    let formData = {
        zoho_id: ''
    }

    const formSchema = Yup.object().shape({
        zoho_id: Yup.number().required('Required'),
    })

    // const handelAddZohoToSociety = (form) => {
    //     API.graphql({
    //         query: searchSocieties,
    //         variables: {
    //             filter: { zohoId: { eq: form.zoho_id } }
    //         }
    //     }).then((res) => {
    //         console.log(res, "res");
    //         if (res.data.searchSocieties.items.length === 0) {
    //             API.graphql({
    //                 query: updateSociety,
    //                 variables: {
    //                     input: {
    //                         id: params.id,
    //                         zohoId: form.zoho_id
    //                     }
    //                 }
    //             }).then(() => {
    //                 navigate(`society/${params.id}/users`)
    //             })
    //         } else {
    //             toast.warning(`This Id was already mapped to ${res.data.searchSocieties.items[0].name} society`)
    //         }
    //     })
    // }

    const handelAddZohoToSociety = async (form) => {
       const response = await API.graphql({
            query: searchSocieties,
            variables: {
                filter: { zohoId: { eq: form.zoho_id } }
            }
        })
                  
            if (response.data.searchSocieties.items.length === 0) {
             const response = await API.graphql({
                    query: updateSociety,
                    variables: {
                        input: {
                            id: params.id,
                            zohoId: form.zoho_id
                        }
                    }
                })                
            
                if(response){
                    toast.success(`ZohoId Linked Successfully to ${form.zoho_id}`)
                    // navigate(`society/${params.id}/users`)
                    navigate(`/society`)
                }
         
            } else {
                toast.warning(`This Id was already mapped to ${response.data.searchSocieties.items[0].name} society`)
            }
    }


    return (
        <section>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h1 className='header-title'>
                                            Add User
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handelAddZohoToSociety} validateOnChange={false} validateOnBlur={false}>
                            {({ handleChange, handleSubmit, setFieldValue, values, touched, errors }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-group'>
                                            <label className='form-label mb-0'>Zoho Organisation Id <span className='text-danger'>*</span></label>
                                            <NumberFormat
                                                className='form-control'
                                                name='zoho_id'
                                                value={values.zoho_id}
                                                onChange={handleChange}
                                                placeholder='Enter Id'
                                            />
                                            {errors.zoho_id && touched.zoho_id && <div className='text-danger mt-2 ms-1 h5'>{errors.zoho_id}</div>}
                                        </div>
                                        <div>
                                            <Spinner show={spinner}>
                                                <button className="btn w-100 btn-primary" type='submit'>Link to Zoho</button>
                                            </Spinner>
                                            <Link to={`/society/${params.id}/users`}>
                                                <div className='btn w-100 btn-link text-muted mt-2' type='button'>
                                                    Cancel
                                                </div>
                                            </Link>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LinkToZoho
