import React from 'react'

function Subscription() {
    return (
        <h3>
            Subscription
        </h3>
    )
}

export default Subscription
