/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSocietyUser = /* GraphQL */ `
  mutation CreateSocietyUser(
    $input: CreateSocietyUserInput!
    $condition: ModelSocietyUserConditionInput
  ) {
    createSocietyUser(input: $input, condition: $condition) {
      id
      name
      userId
      user {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      societyId
      society {
        id
        zohoId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      role
      createdAt
      updatedAt
    }
  }
`;
export const updateSocietyUser = /* GraphQL */ `
  mutation UpdateSocietyUser(
    $input: UpdateSocietyUserInput!
    $condition: ModelSocietyUserConditionInput
  ) {
    updateSocietyUser(input: $input, condition: $condition) {
      id
      name
      userId
      user {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      societyId
      society {
        id
        zohoId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      role
      createdAt
      updatedAt
    }
  }
`;
export const deleteSocietyUser = /* GraphQL */ `
  mutation DeleteSocietyUser(
    $input: DeleteSocietyUserInput!
    $condition: ModelSocietyUserConditionInput
  ) {
    deleteSocietyUser(input: $input, condition: $condition) {
      id
      name
      userId
      user {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      societyId
      society {
        id
        zohoId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      role
      createdAt
      updatedAt
    }
  }
`;
export const createAmenity = /* GraphQL */ `
  mutation CreateAmenity(
    $input: CreateAmenityInput!
    $condition: ModelAmenityConditionInput
  ) {
    createAmenity(input: $input, condition: $condition) {
      id
      societyId
      buildingId
      name
      description
      slots
      cost
      free
      media
      active
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAmenity = /* GraphQL */ `
  mutation UpdateAmenity(
    $input: UpdateAmenityInput!
    $condition: ModelAmenityConditionInput
  ) {
    updateAmenity(input: $input, condition: $condition) {
      id
      societyId
      buildingId
      name
      description
      slots
      cost
      free
      media
      active
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAmenity = /* GraphQL */ `
  mutation DeleteAmenity(
    $input: DeleteAmenityInput!
    $condition: ModelAmenityConditionInput
  ) {
    deleteAmenity(input: $input, condition: $condition) {
      id
      societyId
      buildingId
      name
      description
      slots
      cost
      free
      media
      active
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAmenityBooking = /* GraphQL */ `
  mutation CreateAmenityBooking(
    $input: CreateAmenityBookingInput!
    $condition: ModelAmenityBookingConditionInput
  ) {
    createAmenityBooking(input: $input, condition: $condition) {
      id
      amenityId
      amenity {
        id
        societyId
        buildingId
        name
        description
        slots
        cost
        free
        media
        active
        createdAt
        updatedAt
      }
      residentId
      resident {
        id
        name
        societyId
        buildingId
        unitId
        userId
        type
        approved
        zohoId
        createdAt
        updatedAt
      }
      societyId
      society {
        id
        zohoId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      unitId
      unit {
        id
        zohoId
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      slotStartDateTime
      slotEndDateTime
      status
      amount
      invoiceId
      invoiceStatus
      createdByUser {
        id
        name
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      invoiceDate
      dueDate
      statusHistory
      createdAt
      updatedAt
      amenityBookingCreatedByUserId
    }
  }
`;
export const updateAmenityBooking = /* GraphQL */ `
  mutation UpdateAmenityBooking(
    $input: UpdateAmenityBookingInput!
    $condition: ModelAmenityBookingConditionInput
  ) {
    updateAmenityBooking(input: $input, condition: $condition) {
      id
      amenityId
      amenity {
        id
        societyId
        buildingId
        name
        description
        slots
        cost
        free
        media
        active
        createdAt
        updatedAt
      }
      residentId
      resident {
        id
        name
        societyId
        buildingId
        unitId
        userId
        type
        approved
        zohoId
        createdAt
        updatedAt
      }
      societyId
      society {
        id
        zohoId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      unitId
      unit {
        id
        zohoId
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      slotStartDateTime
      slotEndDateTime
      status
      amount
      invoiceId
      invoiceStatus
      createdByUser {
        id
        name
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      invoiceDate
      dueDate
      statusHistory
      createdAt
      updatedAt
      amenityBookingCreatedByUserId
    }
  }
`;
export const deleteAmenityBooking = /* GraphQL */ `
  mutation DeleteAmenityBooking(
    $input: DeleteAmenityBookingInput!
    $condition: ModelAmenityBookingConditionInput
  ) {
    deleteAmenityBooking(input: $input, condition: $condition) {
      id
      amenityId
      amenity {
        id
        societyId
        buildingId
        name
        description
        slots
        cost
        free
        media
        active
        createdAt
        updatedAt
      }
      residentId
      resident {
        id
        name
        societyId
        buildingId
        unitId
        userId
        type
        approved
        zohoId
        createdAt
        updatedAt
      }
      societyId
      society {
        id
        zohoId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      unitId
      unit {
        id
        zohoId
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      slotStartDateTime
      slotEndDateTime
      status
      amount
      invoiceId
      invoiceStatus
      createdByUser {
        id
        name
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      invoiceDate
      dueDate
      statusHistory
      createdAt
      updatedAt
      amenityBookingCreatedByUserId
    }
  }
`;
export const createVisitor = /* GraphQL */ `
  mutation CreateVisitor(
    $input: CreateVisitorInput!
    $condition: ModelVisitorConditionInput
  ) {
    createVisitor(input: $input, condition: $condition) {
      id
      societyId
      unitId
      unit {
        id
        zohoId
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      visitor {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      resident {
        id
        name
        societyId
        buildingId
        unitId
        userId
        type
        approved
        zohoId
        createdAt
        updatedAt
      }
      validFrom
      validTo
      type
      preApproved
      entries {
        nextToken
      }
      createdAt
      updatedAt
      visitorVisitorId
      visitorResidentId
    }
  }
`;
export const updateVisitor = /* GraphQL */ `
  mutation UpdateVisitor(
    $input: UpdateVisitorInput!
    $condition: ModelVisitorConditionInput
  ) {
    updateVisitor(input: $input, condition: $condition) {
      id
      societyId
      unitId
      unit {
        id
        zohoId
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      visitor {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      resident {
        id
        name
        societyId
        buildingId
        unitId
        userId
        type
        approved
        zohoId
        createdAt
        updatedAt
      }
      validFrom
      validTo
      type
      preApproved
      entries {
        nextToken
      }
      createdAt
      updatedAt
      visitorVisitorId
      visitorResidentId
    }
  }
`;
export const deleteVisitor = /* GraphQL */ `
  mutation DeleteVisitor(
    $input: DeleteVisitorInput!
    $condition: ModelVisitorConditionInput
  ) {
    deleteVisitor(input: $input, condition: $condition) {
      id
      societyId
      unitId
      unit {
        id
        zohoId
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      visitor {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      resident {
        id
        name
        societyId
        buildingId
        unitId
        userId
        type
        approved
        zohoId
        createdAt
        updatedAt
      }
      validFrom
      validTo
      type
      preApproved
      entries {
        nextToken
      }
      createdAt
      updatedAt
      visitorVisitorId
      visitorResidentId
    }
  }
`;
export const createVisitorEntry = /* GraphQL */ `
  mutation CreateVisitorEntry(
    $input: CreateVisitorEntryInput!
    $condition: ModelVisitorEntryConditionInput
  ) {
    createVisitorEntry(input: $input, condition: $condition) {
      id
      visitorId
      visitor {
        id
        societyId
        unitId
        validFrom
        validTo
        type
        preApproved
        createdAt
        updatedAt
        visitorVisitorId
        visitorResidentId
      }
      societyId
      unitId
      security {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      type
      approved
      entry
      exit
      media
      notes
      token
      createdAt
      updatedAt
      visitorEntrySecurityId
    }
  }
`;
export const updateVisitorEntry = /* GraphQL */ `
  mutation UpdateVisitorEntry(
    $input: UpdateVisitorEntryInput!
    $condition: ModelVisitorEntryConditionInput
  ) {
    updateVisitorEntry(input: $input, condition: $condition) {
      id
      visitorId
      visitor {
        id
        societyId
        unitId
        validFrom
        validTo
        type
        preApproved
        createdAt
        updatedAt
        visitorVisitorId
        visitorResidentId
      }
      societyId
      unitId
      security {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      type
      approved
      entry
      exit
      media
      notes
      token
      createdAt
      updatedAt
      visitorEntrySecurityId
    }
  }
`;
export const deleteVisitorEntry = /* GraphQL */ `
  mutation DeleteVisitorEntry(
    $input: DeleteVisitorEntryInput!
    $condition: ModelVisitorEntryConditionInput
  ) {
    deleteVisitorEntry(input: $input, condition: $condition) {
      id
      visitorId
      visitor {
        id
        societyId
        unitId
        validFrom
        validTo
        type
        preApproved
        createdAt
        updatedAt
        visitorVisitorId
        visitorResidentId
      }
      societyId
      unitId
      security {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      type
      approved
      entry
      exit
      media
      notes
      token
      createdAt
      updatedAt
      visitorEntrySecurityId
    }
  }
`;
export const createVechicle = /* GraphQL */ `
  mutation CreateVechicle(
    $input: CreateVechicleInput!
    $condition: ModelVechicleConditionInput
  ) {
    createVechicle(input: $input, condition: $condition) {
      id
      societyId
      unitId
      name
      number
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateVechicle = /* GraphQL */ `
  mutation UpdateVechicle(
    $input: UpdateVechicleInput!
    $condition: ModelVechicleConditionInput
  ) {
    updateVechicle(input: $input, condition: $condition) {
      id
      societyId
      unitId
      name
      number
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteVechicle = /* GraphQL */ `
  mutation DeleteVechicle(
    $input: DeleteVechicleInput!
    $condition: ModelVechicleConditionInput
  ) {
    deleteVechicle(input: $input, condition: $condition) {
      id
      societyId
      unitId
      name
      number
      type
      createdAt
      updatedAt
    }
  }
`;
export const createResident = /* GraphQL */ `
  mutation CreateResident(
    $input: CreateResidentInput!
    $condition: ModelResidentConditionInput
  ) {
    createResident(input: $input, condition: $condition) {
      id
      name
      societyId
      society {
        id
        zohoId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      buildingId
      building {
        id
        societyId
        name
        createdAt
        updatedAt
      }
      unitId
      unit {
        id
        zohoId
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      userId
      user {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      type
      approved
      zohoId
      complaints {
        nextToken
      }
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateResident = /* GraphQL */ `
  mutation UpdateResident(
    $input: UpdateResidentInput!
    $condition: ModelResidentConditionInput
  ) {
    updateResident(input: $input, condition: $condition) {
      id
      name
      societyId
      society {
        id
        zohoId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      buildingId
      building {
        id
        societyId
        name
        createdAt
        updatedAt
      }
      unitId
      unit {
        id
        zohoId
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      userId
      user {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      type
      approved
      zohoId
      complaints {
        nextToken
      }
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteResident = /* GraphQL */ `
  mutation DeleteResident(
    $input: DeleteResidentInput!
    $condition: ModelResidentConditionInput
  ) {
    deleteResident(input: $input, condition: $condition) {
      id
      name
      societyId
      society {
        id
        zohoId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      buildingId
      building {
        id
        societyId
        name
        createdAt
        updatedAt
      }
      unitId
      unit {
        id
        zohoId
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      userId
      user {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      type
      approved
      zohoId
      complaints {
        nextToken
      }
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUnit = /* GraphQL */ `
  mutation CreateUnit(
    $input: CreateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    createUnit(input: $input, condition: $condition) {
      id
      zohoId
      societyId
      buildingId
      building {
        id
        societyId
        name
        createdAt
        updatedAt
      }
      type
      name
      description
      size
      residents {
        nextToken
      }
      vechicles {
        nextToken
      }
      visitors {
        nextToken
      }
      visitorEntries {
        nextToken
      }
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUnit = /* GraphQL */ `
  mutation UpdateUnit(
    $input: UpdateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    updateUnit(input: $input, condition: $condition) {
      id
      zohoId
      societyId
      buildingId
      building {
        id
        societyId
        name
        createdAt
        updatedAt
      }
      type
      name
      description
      size
      residents {
        nextToken
      }
      vechicles {
        nextToken
      }
      visitors {
        nextToken
      }
      visitorEntries {
        nextToken
      }
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUnit = /* GraphQL */ `
  mutation DeleteUnit(
    $input: DeleteUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    deleteUnit(input: $input, condition: $condition) {
      id
      zohoId
      societyId
      buildingId
      building {
        id
        societyId
        name
        createdAt
        updatedAt
      }
      type
      name
      description
      size
      residents {
        nextToken
      }
      vechicles {
        nextToken
      }
      visitors {
        nextToken
      }
      visitorEntries {
        nextToken
      }
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBuilding = /* GraphQL */ `
  mutation CreateBuilding(
    $input: CreateBuildingInput!
    $condition: ModelBuildingConditionInput
  ) {
    createBuilding(input: $input, condition: $condition) {
      id
      societyId
      name
      units {
        nextToken
      }
      amenities {
        nextToken
      }
      residents {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBuilding = /* GraphQL */ `
  mutation UpdateBuilding(
    $input: UpdateBuildingInput!
    $condition: ModelBuildingConditionInput
  ) {
    updateBuilding(input: $input, condition: $condition) {
      id
      societyId
      name
      units {
        nextToken
      }
      amenities {
        nextToken
      }
      residents {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBuilding = /* GraphQL */ `
  mutation DeleteBuilding(
    $input: DeleteBuildingInput!
    $condition: ModelBuildingConditionInput
  ) {
    deleteBuilding(input: $input, condition: $condition) {
      id
      societyId
      name
      units {
        nextToken
      }
      amenities {
        nextToken
      }
      residents {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createState = /* GraphQL */ `
  mutation CreateState(
    $input: CreateStateInput!
    $condition: ModelStateConditionInput
  ) {
    createState(input: $input, condition: $condition) {
      id
      name
      cities {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateState = /* GraphQL */ `
  mutation UpdateState(
    $input: UpdateStateInput!
    $condition: ModelStateConditionInput
  ) {
    updateState(input: $input, condition: $condition) {
      id
      name
      cities {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteState = /* GraphQL */ `
  mutation DeleteState(
    $input: DeleteStateInput!
    $condition: ModelStateConditionInput
  ) {
    deleteState(input: $input, condition: $condition) {
      id
      name
      cities {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCity = /* GraphQL */ `
  mutation CreateCity(
    $input: CreateCityInput!
    $condition: ModelCityConditionInput
  ) {
    createCity(input: $input, condition: $condition) {
      id
      name
      societies {
        nextToken
      }
      stateId
      state {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCity = /* GraphQL */ `
  mutation UpdateCity(
    $input: UpdateCityInput!
    $condition: ModelCityConditionInput
  ) {
    updateCity(input: $input, condition: $condition) {
      id
      name
      societies {
        nextToken
      }
      stateId
      state {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCity = /* GraphQL */ `
  mutation DeleteCity(
    $input: DeleteCityInput!
    $condition: ModelCityConditionInput
  ) {
    deleteCity(input: $input, condition: $condition) {
      id
      name
      societies {
        nextToken
      }
      stateId
      state {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSociety = /* GraphQL */ `
  mutation CreateSociety(
    $input: CreateSocietyInput!
    $condition: ModelSocietyConditionInput
  ) {
    createSociety(input: $input, condition: $condition) {
      id
      zohoId
      type
      name
      cityId
      address
      photo
      buildings {
        nextToken
      }
      units {
        nextToken
      }
      vechicles {
        nextToken
      }
      visitors {
        nextToken
      }
      visitorEntries {
        nextToken
      }
      amenities {
        nextToken
      }
      residents {
        nextToken
      }
      users {
        nextToken
      }
      complaintTypes {
        nextToken
      }
      complaints {
        nextToken
      }
      notices {
        nextToken
      }
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSociety = /* GraphQL */ `
  mutation UpdateSociety(
    $input: UpdateSocietyInput!
    $condition: ModelSocietyConditionInput
  ) {
    updateSociety(input: $input, condition: $condition) {
      id
      zohoId
      type
      name
      cityId
      address
      photo
      buildings {
        nextToken
      }
      units {
        nextToken
      }
      vechicles {
        nextToken
      }
      visitors {
        nextToken
      }
      visitorEntries {
        nextToken
      }
      amenities {
        nextToken
      }
      residents {
        nextToken
      }
      users {
        nextToken
      }
      complaintTypes {
        nextToken
      }
      complaints {
        nextToken
      }
      notices {
        nextToken
      }
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSociety = /* GraphQL */ `
  mutation DeleteSociety(
    $input: DeleteSocietyInput!
    $condition: ModelSocietyConditionInput
  ) {
    deleteSociety(input: $input, condition: $condition) {
      id
      zohoId
      type
      name
      cityId
      address
      photo
      buildings {
        nextToken
      }
      units {
        nextToken
      }
      vechicles {
        nextToken
      }
      visitors {
        nextToken
      }
      visitorEntries {
        nextToken
      }
      amenities {
        nextToken
      }
      residents {
        nextToken
      }
      users {
        nextToken
      }
      complaintTypes {
        nextToken
      }
      complaints {
        nextToken
      }
      notices {
        nextToken
      }
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createComplaintType = /* GraphQL */ `
  mutation CreateComplaintType(
    $input: CreateComplaintTypeInput!
    $condition: ModelComplaintTypeConditionInput
  ) {
    createComplaintType(input: $input, condition: $condition) {
      id
      societyId
      society {
        id
        zohoId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      name
      priorityType
      SOS
      complaints {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateComplaintType = /* GraphQL */ `
  mutation UpdateComplaintType(
    $input: UpdateComplaintTypeInput!
    $condition: ModelComplaintTypeConditionInput
  ) {
    updateComplaintType(input: $input, condition: $condition) {
      id
      societyId
      society {
        id
        zohoId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      name
      priorityType
      SOS
      complaints {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteComplaintType = /* GraphQL */ `
  mutation DeleteComplaintType(
    $input: DeleteComplaintTypeInput!
    $condition: ModelComplaintTypeConditionInput
  ) {
    deleteComplaintType(input: $input, condition: $condition) {
      id
      societyId
      society {
        id
        zohoId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      name
      priorityType
      SOS
      complaints {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createComplaint = /* GraphQL */ `
  mutation CreateComplaint(
    $input: CreateComplaintInput!
    $condition: ModelComplaintConditionInput
  ) {
    createComplaint(input: $input, condition: $condition) {
      id
      residentId
      resident {
        id
        name
        societyId
        buildingId
        unitId
        userId
        type
        approved
        zohoId
        createdAt
        updatedAt
      }
      societyId
      complaintTypeId
      complainType {
        id
        societyId
        name
        priorityType
        SOS
        createdAt
        updatedAt
      }
      description
      assignedTo {
        id
        name
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      comment
      media
      status
      feedback
      rating
      complaintHistory
      type
      createdAt
      updatedAt
      complaintAssignedToId
    }
  }
`;
export const updateComplaint = /* GraphQL */ `
  mutation UpdateComplaint(
    $input: UpdateComplaintInput!
    $condition: ModelComplaintConditionInput
  ) {
    updateComplaint(input: $input, condition: $condition) {
      id
      residentId
      resident {
        id
        name
        societyId
        buildingId
        unitId
        userId
        type
        approved
        zohoId
        createdAt
        updatedAt
      }
      societyId
      complaintTypeId
      complainType {
        id
        societyId
        name
        priorityType
        SOS
        createdAt
        updatedAt
      }
      description
      assignedTo {
        id
        name
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      comment
      media
      status
      feedback
      rating
      complaintHistory
      type
      createdAt
      updatedAt
      complaintAssignedToId
    }
  }
`;
export const deleteComplaint = /* GraphQL */ `
  mutation DeleteComplaint(
    $input: DeleteComplaintInput!
    $condition: ModelComplaintConditionInput
  ) {
    deleteComplaint(input: $input, condition: $condition) {
      id
      residentId
      resident {
        id
        name
        societyId
        buildingId
        unitId
        userId
        type
        approved
        zohoId
        createdAt
        updatedAt
      }
      societyId
      complaintTypeId
      complainType {
        id
        societyId
        name
        priorityType
        SOS
        createdAt
        updatedAt
      }
      description
      assignedTo {
        id
        name
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      comment
      media
      status
      feedback
      rating
      complaintHistory
      type
      createdAt
      updatedAt
      complaintAssignedToId
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      phone
      photo
      birthday
      gender
      role
      residents {
        nextToken
      }
      societies {
        nextToken
      }
      devices {
        nextToken
      }
      notifications {
        nextToken
      }
      pin
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      email
      phone
      photo
      birthday
      gender
      role
      residents {
        nextToken
      }
      societies {
        nextToken
      }
      devices {
        nextToken
      }
      notifications {
        nextToken
      }
      pin
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      email
      phone
      photo
      birthday
      gender
      role
      residents {
        nextToken
      }
      societies {
        nextToken
      }
      devices {
        nextToken
      }
      notifications {
        nextToken
      }
      pin
      createdAt
      updatedAt
    }
  }
`;
export const createNotice = /* GraphQL */ `
  mutation CreateNotice(
    $input: CreateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    createNotice(input: $input, condition: $condition) {
      id
      title
      societyId
      description
      media
      createdBy {
        id
        name
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      noticeCreatedById
    }
  }
`;
export const updateNotice = /* GraphQL */ `
  mutation UpdateNotice(
    $input: UpdateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    updateNotice(input: $input, condition: $condition) {
      id
      title
      societyId
      description
      media
      createdBy {
        id
        name
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      noticeCreatedById
    }
  }
`;
export const deleteNotice = /* GraphQL */ `
  mutation DeleteNotice(
    $input: DeleteNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    deleteNotice(input: $input, condition: $condition) {
      id
      title
      societyId
      description
      media
      createdBy {
        id
        name
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      noticeCreatedById
    }
  }
`;
export const createUserDevice = /* GraphQL */ `
  mutation CreateUserDevice(
    $input: CreateUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    createUserDevice(input: $input, condition: $condition) {
      id
      userId
      type
      app
      name
      meta
      token
      arn
      createdAt
      updatedAt
    }
  }
`;
export const updateUserDevice = /* GraphQL */ `
  mutation UpdateUserDevice(
    $input: UpdateUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    updateUserDevice(input: $input, condition: $condition) {
      id
      userId
      type
      app
      name
      meta
      token
      arn
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserDevice = /* GraphQL */ `
  mutation DeleteUserDevice(
    $input: DeleteUserDeviceInput!
    $condition: ModelUserDeviceConditionInput
  ) {
    deleteUserDevice(input: $input, condition: $condition) {
      id
      userId
      type
      app
      name
      meta
      token
      arn
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userId
      type
      typeId
      app
      senderId
      sender {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      title
      message
      sent
      read
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userId
      type
      typeId
      app
      senderId
      sender {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      title
      message
      sent
      read
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userId
      type
      typeId
      app
      senderId
      sender {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      title
      message
      sent
      read
      createdAt
      updatedAt
    }
  }
`;
