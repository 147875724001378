import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { searchSocieties } from '../../graphql/queries'
import { customSearchUsersCount } from '../../customQueries/customQueries'
import Spinner from '../../components/spinner'


const Dashboard = () => {
    const [societies, setSocieties] = useState(0)
    const [owner, setOwner] = useState(0)
    const [spinner, showSpinner] = useState(false)

    const handelGetSocieties = () => {
        showSpinner(true)
        API.graphql({
            query: searchSocieties,
        }).then((res) => {
            setSocieties(res?.data?.searchSocieties?.total ? res?.data?.searchSocieties?.total : 0)
        })
    }

    const userLoop = async (nextToken = null) => {
        showSpinner(true)
        let count = 0;
        do {
            const result = await numberOfUsers(nextToken);
            count += result?.count;
            nextToken = result?.nextToken;
        } while (nextToken);
        setOwner(count);
        showSpinner(false)
    }

    const numberOfUsers = async (token) => {
        const response = await API.graphql({
            query: customSearchUsersCount,
            variables: {
                limit: 100,
                filter: { role: { eq: 'OWNER' } },
                nextToken: token,
            },
        });
        const { items, nextToken } = response?.data?.searchUsers;
        return { count: items?.length, nextToken };
    }

    useEffect(() => {
        handelGetSocieties()
        userLoop()
        // eslint-disable-next-line
    }, [])


    return (
        <Spinner show={spinner}>
            <div className="px-2 mt-4">
                <h1>Dashboard</h1>
                <hr />
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl">
                        <div className="card">
                            <div className="card-body">
                                <div className="row align-items-center gx-0">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted mb-2">
                                            Total Societies
                                        </h6>
                                        <span className="h2 mb-0">
                                            {societies}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-building fs-1 mt-3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl">
                        <div className="card">
                            <div className="card-body">
                                <div className="row align-items-center gx-0">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted mb-2">
                                            Total Staff
                                        </h6>
                                        <span className="h2 mb-0">
                                            {owner}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-user fs-1 mt-3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Spinner>
    )
}

export default Dashboard
