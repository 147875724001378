import { useState } from 'react'
import Spinner from '../../../components/spinner'
import { useNavigate } from 'react-router-dom'
import FormControl from '../../../components/formControl'
import { createUser } from '../../../graphql/mutations'
import { toast } from 'react-toastify'
import { API, Auth, Storage } from 'aws-amplify';


import { Formik } from 'formik'
import * as Yup from 'yup'

const AddStaff = () => {
    const [spinner, setSpinner] = useState(false)
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        role: '',
        image: '',
    })

    const roleOptions = [
        {
            value: 'OWNER',
            label: 'OWNER'
        }, {
            value: 'EDITOR',
            label: 'EDITOR'
        }, {
            value: 'VIEWER',
            label: 'VIEWER'
        }
    ]


    const formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Please enter valid email').required('Required'),
        phone: Yup.string().required('Required'),
        role: Yup.object().required('Required'),
        image: Yup.string().required('Required'),
    })


    const handleCreate = async (form) => {
        setSpinner(true)
        const password = 'Buckler@123'
        try {
            const user = await Auth.signUp({
                username: `+91${form.phone}`,
                password: password,
                attributes: {
                    email: form.email.trim().toLowerCase(),
                    phone_number: `+91${form.phone}`
                },
            }
            )
            if (user.userSub) {
                await API.graphql({
                    query: createUser,
                    variables: {
                        input: {
                            id: user?.userSub,
                            name: form?.name,
                            email: form?.email,
                            photo: form?.image,
                            role: form?.role?.label,
                            phone: `+91${form.phone}`
                        }
                    }
                }).then(res => {
                    setTimeout(() => {
                        toast.success('User created successfully')
                        navigate('/staff')
                        setSpinner(false)
                    }, 3000)
                }).catch((err) => {
                    toast.error(err.message || err.errors[0].message)
                    console.log(err.message, err.errors[0].message, err, 'hello error')
                    setSpinner(false)
                })
            }
        }
        catch (error) {
            setSpinner(false)
            console.log(error, 'hello erroe')
            toast.error(error.message)
        }
    }


    const handleUploadImage = async (e, callback, name) => {
        try {
            const file = e.target.files[0]
            const stored = await Storage.put(`buckler-${Math.random().toString(36).substring(2, 15)}.${file.name.split('.')[1]}`, file, { contentType: file.type });
            const url = await Storage.get(stored.key, { level: 'public' })
            let ImageUrl = url.split('?')[0]
            if (ImageUrl) {
                const event = { target: { name: name || '', value: ImageUrl } }
                callback(event)
            }
        } catch (err) {
            console.log(err, 'hello err')
        }
    }

    const handleCnacleCreate = () => {
        navigate(-1)
    }


    return (
        <section>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h1 className='header-title'>
                                            Create Staff
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Spinner show={spinner}>
                            <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleCreate} validateOnChange={false} validateOnBlur={false}>
                                {({ handleChange, handleSubmit, values, errors, touched }) => {
                                    const selectRole = values.role ? roleOptions.filter((x) => x.value === values.role)[0] : null;

                                    return (
                                        <form className="mb-4" onSubmit={handleSubmit}>
                                            <FormControl type="text" title="Name" name='name' placeholder={'Name'} value={values.name} error={errors.name} onChange={handleChange} required={true} />
                                            <FormControl type="email" title="Email" name='email' placeholder={'Email'} value={values.email} error={errors.email} onChange={handleChange} required={true} />
                                            <FormControl type="phone" title="Phone" name='phone' placeholder={'Phone number'} value={values.phone} error={errors.phone} onChange={handleChange} required={true} />
                                            <FormControl type="select" title="Select role" name='role' placeholder={'Role'} options={roleOptions} value={values.role} error={errors.role} onChange={handleChange} required={true} />
                                            <div className='form-group mt-3'>
                                                {values?.image && <img src={values?.image} alt="" width={'500'} className='preview' />}
                                                <div>
                                                    <label>Image</label>
                                                    <input name="image" type="file" accept='image/*' onChange={(e) => { handleUploadImage(e, handleChange, 'image') }} className="form-control" />
                                                    {errors.image && touched.image && <div className='text-danger mt-2 ms-1 h5'>{errors.image}</div>}
                                                </div>
                                            </div>
                                            <button className="btn w-100 btn-primary" type='submit'>Create Staff</button>
                                            <div className='btn w-100 btn-link text-muted mt-2' onClick={() => handleCnacleCreate()} type='button'>
                                                Cancel
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>
                        </Spinner>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddStaff
